<template>
    <div :class="wrapClass">
        <button
            v-bind="$attrs"
            :class="{disabledIfInvalid: !buttonAlwaysActive}"
            :disabled="formStats.inProgress || disabled"
            @click="formStats.isSubmitted = true"
        >
            <slot/>
        </button>
    </div>
</template>

<script>
    export default {
        name: "FormButton",
        inject: ["formStats"],
        inheritAttrs: false,
        props: {
            wrapClass: {type: String, default: "@pt-6 @text-center"},
            buttonAlwaysActive: Boolean,
            disabled: Boolean
        }
    };
</script>
